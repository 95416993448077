const API_MOBILE_URL = process.env.VUE_APP_API_MOBILE_URL
const BASE_MOBILE_URL = API_MOBILE_URL + 'auth/'

export const VISITORS_URL = `${BASE_MOBILE_URL}expositors/visitors`
export const ADMIN_VISITORS_URL = `${BASE_MOBILE_URL}expositors/admin-visitors`
export const DATA_TO_FILTER_VISITORS_URL = `${BASE_MOBILE_URL}expositors/datatofiltervisitors`
export const DATA_TO_FILTER_ADMIN_VISITORS_URL = `${BASE_MOBILE_URL}expositors/datatofilteradminvisitors`
export const EXPORT_VISITORS_URL = `${BASE_MOBILE_URL}expositors/export`
export const EXPORT_ADMIN_VISITORS_URL = `${BASE_MOBILE_URL}expositors/admin-export`
export const GET_VISITOR_INFO_URL = `${BASE_MOBILE_URL}expositors/visitor/`
export const COUNT_VISITORS_URL = `${BASE_MOBILE_URL}expositors/visitors/count`
export const ADMIN_COUNT_VISITORS_URL = `${BASE_MOBILE_URL}expositors/visitors/admin-count`

export const APP_MODULES_URL = `${BASE_MOBILE_URL}app-modules`
export const APP_MODULE_SAVE_IMAGE = `${APP_MODULES_URL}/image`
export const APP_MODULE_SAVE_INTERNAL_IMAGE = `${APP_MODULES_URL}/internal-image`
export const GENERAL_ACTIVITIES_URL = `${BASE_MOBILE_URL}general-activities`
export const GENERAL_INFORMATION_URL = `${BASE_MOBILE_URL}general-information`
export const ACADEMIC_ACTIVITIES_URL = `${BASE_MOBILE_URL}academic-activities`
export const INTEREST_PLACES_URL = `${BASE_MOBILE_URL}interest-places`
export const RESTAURANTS_URL = `${BASE_MOBILE_URL}restaurants`
export const RESTAURANT_CATEGORIES_URL = `${BASE_MOBILE_URL}restaurant-categories`
export const SOCIAL_MEDIA_URL = `${BASE_MOBILE_URL}social-media`

export const API_MOBILE_IMAGES_URL = process.env.VUE_APP_API_MOBILE_IMAGES_URL
