<template>
  <v-card
    :color="color"
    dark
    width="205"
    height="100"
  >
    <v-card-text class="pb-2">
      <div class="row">
        <div class="col-4 d-flex justify-center align-center custom-col">
            <v-icon x-large>
               mdi-{{ icon }}
            </v-icon>
        </div>
        <div class="col-8 d-flex custom-col">
          <div class="row">
            <div class="col-12 pl-3 text-right pr-3" style="padding: 0px;">
              <span>
                <h1>
                  {{ title }}
                </h1>
              </span>
            </div>
            <div class="col-12 mt-2 pr-3 text-right" style="padding: 0px;">
              <span>
                {{ text }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    color: {
      default: '#13E23F',
      type: String
    },
    icon: {
      default: 'fa-home',
      type: String
    },
    title: {
      default: '1',
      type: String
    },
    text: {
      default: 'test',
      type: String
    }
  }
}
</script>
<style scoped>
  .custom-col {
    position: relative;
    top: -15px;
  }
</style>
