<template>
  <fair-layout v-if="fair" :fair-id="fair.id">
    <template v-slot:banner>
      <banner
        v-if="fair"
        :title="fair.name"
        type="banner--admin"
        :options="{padding: '30px'}">
      </banner>
    </template>
    <template v-slot:content>
      <div class="py-3 px-7">
        <div class="row">
          <div class="col-12 d-flex align-center justify-space-between">
            <h2>Visitantes Registrados</h2>
            <v-btn
              color="primary"
              outlined
              class="text--primary text-none elevation-0"
              @click="exportExcel()">
              <v-icon left dark>
                fa-file-excel
              </v-icon>
              Exportar
            </v-btn>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <dashboard-card
              icon="account-search-outline"
              text="Visitantes Escaneados"
              :title="scannedVisitors"
              color="primary"
            ></dashboard-card>
            <dashboard-card class="ml-5"
                            icon="account-group"
                            text="Visitantes Únicos Escaneados"
                            :title="scannedUniqueVisitors"
                            color="secondary"
            ></dashboard-card>
          </v-col>
        </v-row>
      </div>
      <div class="px-7">
        <div class="row">
          <div class="col-6">
            <v-text-field
              v-model="params.filterName"
              label="Nombre Completo"
              v-on:keyup.enter="search"
              outlined
              dense
              single-line
              hide-details
            ></v-text-field>
          </div>
          <div class="col-6">
            <v-select
              v-model="params.filterVisitorCompany"
              :items="visitorCompanies"
              item-text="companyName"
              item-value="companyName"
              outlined
              dense
              label="Empresa Visitante"
            ></v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <v-select
              v-model="params.filterExpositorCompany"
              :items="expositorsCompanies"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Empresa Expositor"
            ></v-select>
          </div>
          <div class="col-6">
            <v-select
              v-model="params.filterPosition"
              :items="positions"
              item-text="companyPosition"
              item-value="companyPosition"
              outlined
              dense
              label="Cargo"
            ></v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-end">
            <v-btn
              color="primary"
              @click="search"
              class="white--text text-none elevation-0 mr-2">
              <v-icon
                left
                dark>
                fa-search
              </v-icon>
              Buscar
            </v-btn>
            <v-btn
              color="default"
              @click="clean"
              class="text-none elevation-0">
              <v-icon
                left
                dark>
                fa-broom
              </v-icon>
              Limpiar
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <data-table ref="visitors" :url="url" :params="params" :headers="headers" :mobile="true">
              <template v-slot:item.name="{ item }">
                {{ item.name.toUpperCase()}}
              </template>
              <template v-slot:item.attendeePosition="{ item }">
                {{ item.attendeePosition.toUpperCase()}}
              </template>
              <template v-slot:item.companyName="{ item }">
                {{ item.companyName.toUpperCase()}}
              </template>
              <template v-slot:item.expositorCompany="{ item }">
                {{ item.expositorCompany.toUpperCase()}}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="nVisitorModal(item)"
                      small
                      color="primary"
                    >
                      <v-icon
                        center
                        dark
                        small
                      >
                        fa fa-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Ver mas</span>
                </v-tooltip>
              </template>
            </data-table>
          </div>
        </div>
        <visitor-modal ref="visitorModal" :fair-id="fair.id" @onClose="onClose"/>
      </div>
    </template>
  </fair-layout>
</template>
<script>
import FairLayout from '@/layouts/FairLayout'
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import { FAIRS_URL } from '@/constants/ServicesConstants'
import {
  DATA_TO_FILTER_ADMIN_VISITORS_URL,
  EXPORT_ADMIN_VISITORS_URL,
  ADMIN_COUNT_VISITORS_URL, ADMIN_VISITORS_URL
} from '@/constants/ServicesMobileConstants'
import headers from '@/views/private/admin-visitors/data/Headers'
import sessionMixin from '@/mixins/sessionMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import DashboardCard from '@/components/dashboard-card/DashboardCard.vue'
import VisitorModal from '@/views/private/visitors/components/VisitorModal'
import Banner from '@/components/banner/Banner'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    FairLayout,
    Banner,
    DataTable,
    VisitorModal,
    DashboardCard
  },
  data () {
    return {
      headers,
      url: ADMIN_VISITORS_URL,
      params: {
        filterName: '',
        filterVisitorCompany: '',
        filterExpositorCompany: '',
        filterPosition: '',
        fairId: this.$route.params.fairId
      },
      fair: null,
      visitorCompanies: [],
      expositorsCompanies: [],
      positions: [],
      scannedVisitors: '',
      scannedUniqueVisitors: '',
      selectedUser: {}
    }
  },
  methods: {
    clean () {
      this.params.filterName = ''
      this.params.filterVisitorCompany = ''
      this.params.filterExpositorCompany = ''
      this.params.filterPosition = ''
      this.search()
    },
    async getCountVisitors () {
      const response = await this.getMobile(ADMIN_COUNT_VISITORS_URL + '/' + this.$route.params.fairId)
      this.scannedVisitors = String(response.data.scannedVisitors)
      this.scannedUniqueVisitors = String(response.data.scannedUniqueVisitors)
    },
    async getDataToFilterVisitors () {
      const response = await this.postMobile(DATA_TO_FILTER_ADMIN_VISITORS_URL + '/' + this.$route.params.fairId)
      this.visitorCompanies = response.data.visitorCompanies
      this.expositorsCompanies = response.data.expositorsCompanies
      this.positions = response.data.positions
    },
    exportExcel () {
      this.showLoader()
      this.$axiosApiMobile.get(EXPORT_ADMIN_VISITORS_URL, { responseType: 'blob', params: this.params })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          if (this.params.filterExpositorCompany && this.params.filterExpositorCompany !== '') {
            const index = this.expositorsCompanies.findIndex((row) => row.id === this.params.filterExpositorCompany)
            link.setAttribute('download', `Visitantes_${this.fair.name}_${this.expositorsCompanies[index].name}.xlsx`)
          } else {
            link.setAttribute('download', `Visitantes_${this.fair.name}.xlsx`)
          }
          document.body.appendChild(link)
          this.preload = false
          link.click()
          this.hideLoader()
        }).catch((error) => {
          this.showError(error)
          this.hideLoader()
        })
    },
    onClose () {
      this.$refs.visitors.getDataFromApi()
    },
    nVisitorModal (item) {
      this.$refs.visitorModal.open(item)
    },
    search () {
      this.$refs.visitors.getDataFromApi()
    },
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.$route.params.fairId)
      this.fair = response.data
    }
  },
  mixins: [
    crudServiceMixin,
    crudServiceMobileMixin,
    loaderMixin,
    notificationMixin
  ],
  name: 'Visitors',
  async created () {
    this.user = JSON.parse(localStorage.getItem('userInfo')).user

    try {
      this.showLoader()
      await this.getFair()
      await this.getCountVisitors()
      await this.getDataToFilterVisitors()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  }
}
</script>
<style scoped>
.custom-col {
  padding-left: 0px !important;
}

.v-card {
  display: inline-block;
}
</style>
